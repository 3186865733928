<template>
  <label class="input-file">
    <div class="input-file__btn">
      <plus-icon size="1.5x" class="icon" />
      <span>{{ $t('upload_file') }}</span>
    </div>
    <span class="input-file__name">
      {{ file ? file.name : $t('file_not_choosen') }}
    </span>
    <input
      type="file"
      @input="handleChange"
      class="visually-hidden"
      ref="file"
    />
  </label>
</template>

<script>
import { PlusIcon } from 'vue-feather-icons'

export default {
  components: {
    PlusIcon
  },
  props: ['value'],
  data: () => ({
    file: null
  }),
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.file = val
      }
    }
  },
  methods: {
    handleChange() {
      this.file = this.$refs.file.files[0]
      this.$emit('input', this.file)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars';

.input-file {
  display: flex;
  align-items: center;
  &__name {
    color: $text-light;
    font-style: italic;
    margin-left: 15px;
    font-size: 0.875rem;
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #f0f0f3;
    border-radius: 2px;
    padding: 10px 15px;
    transition: all 0.2s;
    .icon {
      margin-right: 10px;
    }
    &:hover,
    &:focus {
      background: darken(#f0f0f3, 5);
      cursor: pointer;
    }
    &:active {
      background: darken(#f0f0f3, 10);
    }
  }
}
</style>
