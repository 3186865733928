<template>
  <b-container class="mt-4 mb-5">
    <b-row>
      <b-col cols="12" class="mb-3">
        <h3>{{ $t('send_ques') }}</h3>
      </b-col>
      <b-col cols="12" md="8">
        <form
          @submit.prevent="sendFeedback"
          :class="{ 'form--sent': isSent }"
          :data-txt="$t('sent')"
        >
          <b-row>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="text"
                v-model="form.surname"
                class="input"
                :placeholder="`${$t('last_name')}*`"
                required
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-4 d-flex align-items-center">
              <div class="d-flex align-items-center">
                <span class="mr-5">{{ $t('gender') }}</span>
                <b-form-radio
                  class="mr-3"
                  name="gender"
                  v-model="form.gender"
                  value="male"
                >
                  {{ $t('male') }}
                </b-form-radio>
                <b-form-radio
                  name="gender"
                  v-model="form.gender"
                  value="female"
                >
                  {{ $t('female') }}
                </b-form-radio>
              </div>
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="text"
                class="input"
                v-model="form.name"
                required
                :placeholder="`${$t('name')}*`"
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="date"
                class="input"
                required
                v-model="form.birthdate"
                :placeholder="$t('birthday')"
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="text"
                class="input"
                v-model="form.patronymic"
                :placeholder="$t('dads_name')"
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="email"
                v-model="form.email"
                class="input"
                :placeholder="$t('email')"
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <select name="region" v-model="form.region" class="input">
                <option
                  v-for="region in regions"
                  :key="region.value"
                  :value="region.value"
                >
                  {{ region.label }}
                </option>
              </select>
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="text"
                v-model="form.district"
                class="input"
                :placeholder="$t('district')"
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="text"
                v-model="form.mahalla"
                class="input"
                :placeholder="$t('mahalla')"
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-5">
              <input
                type="text"
                class="input"
                v-model="form.address"
                :placeholder="$t('address_placeholder')"
              />
            </b-col>
            <b-col cols="12" class="mb-5 d-flex align-items-center">
              <div class="d-flex flex-column flex-sm-row">
                <span class="mr-sm-5 mb-2 mb-sm-0"
                  >{{ $t('feedback_type') }}*</span
                >
                <div class="d-flex align-items-center">
                  <b-form-radio
                    class="mr-3"
                    name="type"
                    required
                    v-model="form.type"
                    value="offer"
                  >
                    {{ $t('offer') }}
                  </b-form-radio>
                  <b-form-radio
                    class="mr-3"
                    name="type"
                    required
                    v-model="form.type"
                    value="application"
                  >
                    {{ $t('application') }}
                  </b-form-radio>
                  <b-form-radio
                    name="type"
                    required
                    v-model="form.type"
                    value="complaint"
                    >{{ $t('complain') }}</b-form-radio
                  >
                </div>
              </div>
            </b-col>
            <b-col cols="12" class="mb-5 d-flex align-items-center">
              <div class="d-flex flex-column flex-sm-row">
                <span class="mr-sm-4 mb-2 mb-sm-0"
                  >{{ $t('appeal_transparency') }}*</span
                >
                <div class="d-flex align-items-center">
                  <b-form-radio
                    class="mr-3"
                    name="privancy"
                    required
                    v-model="form.privancy"
                    :value="true"
                  >
                    {{ $t('yes') }}
                  </b-form-radio>
                  <b-form-radio
                    class="mr-3"
                    name="privancy"
                    required
                    v-model="form.privancy"
                    :value="false"
                  >
                    {{ $t('no') }}
                  </b-form-radio>
                </div>
                <div class="prompt ml-auto">
                  {{ $t('appeal_trans_hint') }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" class="mb-5 d-flex align-items-center">
              <div class="d-flex">
                <span class="mr-3 mr-sm-5">{{ $t('entrepreneur') }}</span>
                <b-form-checkbox class="mr-3" v-model="form.entrepreneur">
                  <span class="prompt">
                    {{ $t('enterpreneur_hint') }}
                  </span>
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col cols="12" class="mb-4 d-flex align-items-center">
              <input-file v-model="form.file" />
            </b-col>
            <b-col cols="12">
              <textarea
                :placeholder="`${$t('message')}*`"
                class="input input--textarea"
                required
                v-model="form.message"
              ></textarea>
              <button
                :disabled="isSending"
                class="button button--default button--block mt-4"
                type="submit"
              >
                {{ $t('send') }}
              </button>
            </b-col>
          </b-row>
        </form>
        <i18n path="term" tag="div" class="hint mt-4" for="tos">
          <a
            href="#"
            @click.prevent="$bvModal.show('privacy-modal')"
            class="text-link"
            >{{ $t('tos') }}</a
          >
        </i18n>
      </b-col>
      <b-col cols="12" md="4" class="mt-5 mt-md-0">
        <div class="info mb-3">
          <div class="info__heading">{{ $t('phones') }}:</div>
          <a href="tel:+998712314881" class="info__content">
            +998 71 231-48-81
          </a>
          <a href="tel:+998977542076" class="info__content">
            +998 97 754-20-76
          </a>
        </div>
        <div class="info mb-3">
          <div class="info__heading">{{ $t('fax') }}:</div>
          <a href="tel:+998712314181" class="info__content">
            +998 71 231-41-81
          </a>
        </div>
        <div class="info mb-3">
          <div class="info__heading">{{ $t('email') }}</div>
          <a href="mailto:info@postda.uz" class="info__content">
            info@postda.uz
          </a>
        </div>
        <div class="info mb-3">
          <div class="info__heading">{{ $t('address') }}:</div>
          <div class="info__content">
            {{ $t('address_full') }}
          </div>
        </div>
        <social-links />
      </b-col>
    </b-row>
    <privacy-policy-modal />
  </b-container>
</template>

<script>
import SocialLinks from '@/components/SocialLinks'
import InputFile from '@/components/InputFile'
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal'

export default {
  components: {
    SocialLinks,
    InputFile,
    PrivacyPolicyModal
  },
  data: () => ({
    form: {
      surname: '',
      name: '',
      birthdate: '',
      patronymic: '',
      region: 'toshkent',
      district: '',
      email: '',
      gender: 'male',
      mahalla: '',
      address: '',
      type: 'offer',
      privancy: true,
      entrepreneur: false,
      message: '',
      file: null
    },
    isSending: false,
    isSent: false
  }),
  computed: {
    regions() {
      return [
        { value: 'andijon', label: this.$t('regions.andijon') },
        { value: 'buxoro', label: this.$t('regions.buxoro') },
        { value: 'fargona', label: this.$t('regions.fargona') },
        { value: 'jizzakh', label: this.$t('regions.jizzakh') },
        { value: 'namangan', label: this.$t('regions.namangan') },
        { value: 'navoiy', label: this.$t('regions.navoiy') },
        { value: 'nukus', label: this.$t('regions.nukus') },
        { value: 'qarshi', label: this.$t('regions.qarshi') },
        { value: 'samarqand', label: this.$t('regions.samarqand') },
        { value: 'sirdaryo', label: this.$t('regions.sirdaryo') },
        { value: 'termiz', label: this.$t('regions.termiz') },
        { value: 'toshkent', label: this.$t('regions.toshkent') },
        { value: 'urgench', label: this.$t('regions.urgench') }
      ]
    }
  },
  methods: {
    sendFeedback() {
      if (this.isSending || this.isSent) return
      this.isSending = true
      const formData = new FormData()
      const data = { ...this.form }
      data.address = data.mahalla + ' ' + data.address
      delete data.file
      if (this.form.file) formData.append('files.file', this.form.file)
      formData.append('data', JSON.stringify(data))
      this.$store
        .dispatch('sendFeedback', formData)
        .then(() => (this.isSent = true))
        .finally(() => (this.isSending = false))
    }
  },
  metaInfo() {
    return {
      title: this.$t('nav.feedback')
    }
  }
}
</script>
